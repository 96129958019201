import { defineStore } from 'pinia'

export const useUiStore = defineStore('ui', {
  state: () => ({
    showMobile: <boolean>false,
    heroView: <boolean>true,
    propertySlideoverOpen: <boolean>false,
    propertySlideOverData: <{
      id: string
    }>{},
    activeBranchTab: <string>'',
  }),

  actions: {
    hideMobileMenu() {
      this.showMobile = false
    },
    showMobileMenu() {
      this.showMobile = true
    },
    isHeroView(bool: boolean) {
      this.heroView = bool
    },
    openPropertySlideover() {
      this.propertySlideoverOpen = true
    },
    closePropertySlideover() {
      this.propertySlideoverOpen = false
    },
    setPropertySlideoverData(data: any) {
      this.propertySlideOverData = data
    },
    setActiveBranchTab(tab: string) {
      this.activeBranchTab = tab
    },
  },
})
